<template>
  <div></div>
</template>

<script>
import Vue from "vue";
import { uuid } from "vue-uuid";

export default {
  data() {
    return {
      code: "",
      sourceAppId: ""
    };
  },
  computed: {},
  mounted() {
    if (!Vue.$cookies.get("device_id")) {
      Vue.$cookies.set("device_id", uuid.v1());
    }

    this.code = this.$route.query.code;
    this.sourceAppId = this.$route.query.source_app_id;

    if (this.code == "" || this.sourceAppId == "") {
      this.$router.push("/");
    }

    this.$store.dispatch("auth/fetchCallback", {  
      code: this.code,
      sourceAppId: this.sourceAppId
    });
  }
};
</script>
